import cfg from '../../config'

const chatApi = {
    getUrl,
}

function getUrl(connectorId: string) {
    return `${cfg.chatEndpoint}/?connectorId=${connectorId}&rando=${Math.random()}`
}

export default chatApi
