<template>
    <div style="width: 100%; height: 94vh;">
        <iframe style="width: 100%; height: 100%;" class="wget" :src="chatUrl"></iframe>
    </div>
</template>

<script>
import { useProject } from '@/hooks/useProject'
import chatApi from '@api/chat'
import { getCurrentInstance } from 'vue'

export default {
    name: 'EmbeddedPage',
    computed: {
        project() {
            const { project } = useProject()
            return project.value
        },
        waConnector() {
            return this.project?.connectors.find(con => con.connectorType === 'whatsappBasis')
        },
        chatUrl() {
            if (!this.waConnector) return null
            return chatApi.getUrl(this.waConnector?.connectorId)
        },
    },
    setup(){
        const {project} = useProject()
        const root = getCurrentInstance().proxy
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

        eventer(messageEvent,function(e) {
            console.log(e.data === project.value.id)
            root.$router.replace(`/project/${project.value.id}`)
        },false);
    }
}
</script>

<style>
</style>
